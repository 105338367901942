<script setup lang="ts">
import { lgOptions, mobileModalOptions } from "./Detail.carouselOptions"
import { sm } from "~/utils/breakpoints"
import "swiper/css"
import "swiper/css/zoom"
import { Zoom } from "swiper/modules"
import { ModalDetailProps } from "./ModalDetail.props"

const props = withDefaults(defineProps<ModalDetailProps>(), {})

const swiperPropsDesktop = {
  ...lgOptions,
  allowTouchMove: false
}

const swiperPropsMobile = {
  ...mobileModalOptions,
  allowTouchMove: true,
  zoom: { maxRatio: 3 },
  modules: [Zoom]
}
</script>

<template>
  <Dialog
    :open="isGalleryModalOpen"
    @close="toggleGalleryModal"
    class="relative z-50"
  >
    <div class="fixed inset-0 overflow-y-auto md:overflow-hidden">
      <DialogPanel class="min-h-screen w-full bg-white">
        <button
          :tabindex="sm ? '1' : '2'"
          @click="toggleGalleryModal"
          class="
            fixed
            right-4
            top-4
            z-20
            outline-none
            md:right-[71px]
            md:top-6
            lg:right-[228px]
          "
        >
          <UtilsIcon name="Close.svg" class="h-10 w-10 md:h-8 md:w-8" />
        </button>

        <div
          v-if="gallery"
          class="
            md:padded
            relative
            flex
            h-[100svh]
            w-full
            flex-col
            justify-end
            md:grid
            md:h-screen
            md:grid-cols-12
            md:items-center
            md:py-10
          "
        >
          <div class="relative h-full md:col-span-7 md:mb-0 md:h-auto">
            <AppCarousel
              class="carousel-overflowed h-full select-none"
              :swiper-props="sm ? swiperPropsMobile : swiperPropsDesktop"
              :items="gallery"
              :key="sm ? 1 : 0"
              @slideChange="modalSlideChanged"
              @swiper="onModalSwiper"
            >
              <template #default="{ item }">
                <UtilsWrapperImage
                  class="
                    modal-main-image
                    wrapper-image-fit-contain
                    max-h-full
                    border-0
                    md:block
                    md:max-h-[70svh]
                  "
                  :="item"
                  sizes="sm:400px md:800px lg:1000px xl:1200px"
                  :class="{
                    'swiper-zoom-container': sm
                  }"
                />
              </template>
            </AppCarousel>
          </div>

          <div
            class="
              relative
              mt-6
              flex
              h-44
              w-full
              justify-center
              md:col-span-5 md:col-start-9
              md:m-0
              md:flex
              md:h-full
              md:flex-col
              md:justify-start
              md:pt-28
            "
          >
            <div v-if="!sm">
              <h1 v-if="title" class="beaver-bold md:dolphin-bold my-4">
                {{ title }}
              </h1>
              <p v-if="description" class="beaver mb-12 text-black-80">
                {{ description }}
              </p>
            </div>
            <div
              class="
                flex
                items-start
                gap-3
                overflow-x-auto
                pr-10
                scrollbar-hide
                md:flex-wrap
                md:pr-0
              "
            >
              <UtilsWrapperImage
                v-for="(img, index) in gallery"
                :="img"
                :key="index"
                class="
                  wrapper-image-fixed-76
                  modal-image-thumbnail
                  ml-3
                  h-[78px]
                  w-[78px]
                  shrink-0
                  transform-gpu
                  snap-center
                  shadow-02
                  outline-none
                  md:ml-0
                  [&_img]:object-contain
                "
                :class="{
                  'border border-[var(--selectableCard-border-color)]':
                    index === activeIndex
                }"
                @click="clickModalThumbModalCarousel(index)"
                @keypress.enter="clickModalThumbModalCarousel(index)"
                :tabindex="sm ? '2' : '1'"
                sizes="sm:100vw md:100vw lg:100px xl:100px"
              />
            </div>
          </div>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<style scoped lang="scss">

:deep {
  .modal-main-image {
    picture {
      @apply max-h-full;
    }
  }
}

</style>
